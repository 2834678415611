import { PageIdType } from 'generated-graphql';
import { initializeApollo } from 'lib/apolloClient';
import BlockMix from '@/components/BlockMix/BlockMix';

import { getGlobalData } from 'lib/global.js';
import { getEntityBlocks } from 'gql/common/blocks';

import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import { useIsomorphicLayoutEffect } from '@/componentsWP/ScrollPlayback/ScrollPlayback';
import Head from 'next/head';
import TransitionContext from '@/context/TransitionContext';
import { useContext, useEffect, useLayoutEffect } from 'react';
import Script from 'next/script';
import appConfig from '@/app.config';

export default function Home({
  // pageData,
  pageBlocks,
  menus,
}: {
  // pageData: any;
  pageBlocks: any;
  menus: { main: any; footer: any };
}) {

  return (
    <div>
      <Head>
        <style>{pageBlocks?.inlineCss}</style>
      </Head>

      <BlockMix
        blocksData={pageBlocks?.editorBlocks}
        acfData={pageBlocks?.builder?.sections}
      />
    </div>
  );
}

export async function getStaticProps() {
  const apolloClient = initializeApollo();

  const pageBlocks = await apolloClient.query({
    query: getEntityBlocks(),
    variables: {
      id: 'homepage',
      idType: PageIdType.Uri,
      asPreview: false,
    },
  });
  const pageProps = {
    pageBlocks: pageBlocks?.data?.page,
  };

  return await getGlobalData(pageProps, apolloClient, { revalidate: appConfig.revalidate });
}
